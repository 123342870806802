<template>
  <div class="container container-narrow">
    <div class="welcome-flow-container">
      <div class="row align-items-center">
        <div class="pj-loading loader_pos" v-if="isLoading" ><i class="fas fa-circle-notch fa-spin"></i></div>
        <div class="col-12" v-else>
          <!-- <div class="alert-floating alert-red" v-show="accountDeleted && user.process.status != 'error'">
            <p>Account deleted</p>
          </div> -->
          <div class="text-center">
            <img src="~@/assets/excelerate-logo.png" alt="Excelerate" title="Excelerate" class="logo">
          </div>
          <div class="text-center my-5">
            <h2>Your profile has been upgraded</h2>
            <p>Our platform has been updated and is now better than ever. <br> Request a new password to access your Excelerate profile.</p>
            <a href="/forgot-password" class="pink-color font-weight-bold">Get your new Excelerate password here</a>
            <p class="mt-2">Enjoy 💜</p>
          </div>
          <div class="widget mb-3">
            <div class="widget-splash splash-line"></div>
            <div class="widget-body" v-if="!$isSignedIn()">
              <h4>{{pageContent && pageContent.formHeader ? pageContent.formHeader : 'Sign in'}}</h4>
              <form @submit.prevent="login">
                <div class="form-group">
                  <label>{{ pageContent && pageContent.formEmailLabel ? pageContent.formEmailLabel : 'Email'}}</label>
                  <input type="email" v-model="email" name="email" class="form-control form-control-lg"
                    autocomplete="email" :placeholder="pageContent && pageContent.formEmailPlaceholder ? pageContent.formEmailPlaceholder : 'Enter your email'" autofocus required>
                </div>
                <div class="form-group">
                  <label>{{ pageContent && pageContent.formPasswordLabel ? pageContent.formPasswordLabel : 'Password'}} </label>
                  <input type="password" v-model="password" name="current-password" class="form-control form-control-lg"
                    :placeholder="pageContent && pageContent.formPasswordPlaceholder ? pageContent.formPasswordPlaceholder : 'Enter your password'" autocomplete="current-password" required>
                </div>
                <div class="alert alert-danger" v-if="errorMessage">
                  <span v-html="errorMessage"> </span>
                </div>
                <div class="form-group">
                  <button class="btn btn-primary btn-block btn-lg" :disabled="isUpdating">
                    <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    <span v-else>
                      {{ pageContent && pageContent.formSubmitButton ? pageContent.formSubmitButton : 'Sign in'}} 
                    </span>
                  </button>
                </div>
              </form>
              <div>
                <div class="mt-2 forgot-link">
                  <router-link to="/forgot-password" class="color-first"><b>{{ pageContent && pageContent.formForgotPasswordButton ? pageContent.formForgotPasswordButton : 'Forgot Password'}}</b></router-link>
                </div>
              </div>
            </div>
            <div class="widget-body" v-else>
              <div>
                <h4>Welcome back</h4>
                <p>You are signed in.</p>
                <div class="mt-2">
                  <router-link to="/" class="btn btn-primary">Go to dashboard</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="widget-footer-note" v-if="!$isSignedIn()">
            {{ pageContent && pageContent.pageSigninText ? pageContent.pageSigninText + ' ' : 'Don\'t have an account ? Sign up as '}}<router-link to="/student-signup"
              class="color-first">{{ pageContent && pageContent.pageSignInLinkStudent ? pageContent.pageSignInLinkStudent : 'Student'}}</router-link>/<router-link to="/professional-signup"
              class="color-first">{{ pageContent && pageContent.pageSignInLinkProfessional ? pageContent.pageSignInLinkProfessional : 'Professional'}}</router-link>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { base64String, base64ToString } from '../../utils/utils'


// const isAuthenticated = localStorage.getItem('auth-token')
let isAuthenticated = localStorage.getItem('auth-token')
if(isAuthenticated) base64ToString(isAuthenticated)
export default {
  name: 'SignInForm',
  data: function () {
    return {
      email: '', //cw@bywerner.com
      password: '', //123456
      failureMessage: '',
      successMessage: '',
    }
  },
  metaInfo: {
    title: "Sign in / login to Excelerate | Excelerate.dk",
    meta: [
      { name: 'description', content: 'Sign in / login to your Excelerate profile here. Go to Excelerate login/sign in page.' },
      { property: 'og:title', content: "Sign in / login to Excelerate | Excelerate.dk" },
      { property: 'og:description', content: "Sign in / login to your Excelerate profile here. Go to Excelerate login/sign in page." },
    ]
  },
  created() {
    this.$store.dispatch('clearAuthState')
    if(isAuthenticated) this.$router.push('/')
  },
  computed: {
    errorMessage() {
      return this.$store.getters.authErrorMessage
    },
    isUpdating() {
      return this.$store.getters.authIsUpdating
    },
    isLoading(){
      return this.$store.getters.generalIsLoading
    },
    pageContent(){
      return this.$store.getters.signInContent
    }
  },
  methods: {
    login() {
      const user = {
        email: this.email,
        password: this.password
      }
    this.$store.dispatch('signIn', user);
    },
  }
}
</script>
<style scoped>
.forgot-link {
  text-align: center;
  font-size: 16px;
  margin-top: 1.5rem;
}

.alert-floating {
  background: red;
  position: fixed;
  width: 100%;
  /* height: 90px; */
  color: #fff;
  left: 0;
  top: 0;
  z-index: 1080;
  text-align: center;
}

.alert-floating p {
  font-size: 24px;
  font-weight: 500;
  margin: 0.8rem auto;
}
</style>